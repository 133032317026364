<template>
    <front-layout>
        <v-card
            class="mx-auto my-auto pa-5"
            max-width="900"
            min-width="500"
            raised
        >
            <v-card-title>Login</v-card-title>
            <v-card-text>
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.email"
                                    label="Email"
                                    v-model="email"
                                    outlined
                                    color="dark"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.password"
                                    type="password"
                                    label="Password"
                                    outlined
                                    v-model="password"
                                    color="dark"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="text-center justify-space-around">
                <div>
                    <div>
                        <v-btn color="secondary" rounded @click="handleLogin"
                            >Login</v-btn
                        >
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </front-layout>
</template>

<script>
export default {
    name: "Login",
    components: { FrontLayout: () => import("../layouts/FrontLayout") },
    data: function () {
        return {
            email: "",
            password: "",
            errors: {},
        };
    },
    methods: {
        async handleLogin() {
            const { email, password } = this;
            this.$auth
                .login({ data: { email, password }, redirect: null })
                .then(({ data }) => {
                    if (
                        data.data.roles.findIndex((role) => role === "admin") >
                        -1
                    ) {
                        this.$router.push(
                            this.$auth.options.loginData.redirect.admin
                        );
                    }
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
